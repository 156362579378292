import { useMemo, Fragment } from 'react'

import { State } from "./App";
import { logo, line, socials, baseUrl } from "./constants"
import './index'


const Signature = (props: State) => {
  const { fullName, credentials, phone, website } = props

  const currentWebsite = useMemo(() => {
    return website === baseUrl ? website.replace('https://', '').slice(0, -1) : website.replace('https://', '')
  }, [website])

  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      style={{
        whiteSpace: "nowrap",
        width: "100%",
        maxWidth: "538px",
        backgroundColor: "#FFF",
        fontFamily: "Inter, sans-serif",
        overflow: "none",
        position: 'relative'
      }}
      className="signature">
      <tbody>
        <tr>
          <td colSpan={4} >
            <img src={line}
              alt="line" style={{ width: '100%', height: "10px", display: 'block' }} />
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{
            fontWeight: 700,
            fontSize: "17px",
            lineHeight: "20px",
            color: "#373737",
            padding: "8px 0 0",
          }}>
            {fullName}
          </td>
          <td colSpan={2} style={{ textAlign: "end", padding: "8px 0 0" }}><a
            href={"https://www.modsen-software.com/"}
            target="_blank" rel="noreferrer"
          >
            <img
              src={logo}
              width={92}
              alt="Modsen"
              height={20}

            />
          </a></td>
        </tr>
        <tr>
          <td colSpan={4} style={{ padding: "0 0 3px", textAlign: "start" }}> <p style={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            color: "#F17900",
            margin: 0,
          }}>{credentials === "" ? "" : credentials}</p>
          </td>
        </tr>
        <tr>
          <td colSpan={4} style={{ padding: "8px 0 0" }}>
            <table className="info" cellPadding={1} cellSpacing={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td className="td" colSpan={1} style={{
                    width: "33%",
                    verticalAlign: "bottom",
                    padding: "0",
                  }}>
                    {phone.includes(',') ? phone.split(',')
                      .map((elem, index) =>
                      (
                        <div key={index} style={{ padding: "2px 0 0" }}>
                          <a href={`tel:${elem}`} style={{
                            fontWeight: 300,
                            fontSize: "14px",
                            lineHeight: "19px",
                            color: "#373737",
                            textDecoration: "none",
                          }}>
                            {phone === "" ? "" : <span style={{ lineHeight: "23px" }}>{elem}</span>}
                          </a>
                        </div>)
                      )
                      :
                      <>
                        <a href={`tel:${phone}`} style={{
                          fontWeight: 300,
                          fontSize: "14px",
                          lineHeight: "19px",
                          color: "#373737",
                          textDecoration: "none"
                        }}>
                          {phone === "" ? "" : <span style={{ lineHeight: "19px" }}>{phone}</span>}
                        </a>
                      </>
                    }</td>
                  <td className="website td" colSpan={3} style={{
                    width: "33%",
                    maxWidth: "100%",
                    padding: "0",
                    textAlign: "center",
                    verticalAlign: "top",
                  }} >
                    <a style={{
                      fontWeight: 300,
                      fontSize: "14px",
                      lineHeight: "23px",
                      color: "#373737",
                      textTransform: 'lowercase',
                      width: "100%",
                    }} href={website} target="_blank" rel="noreferrer">
                      {website === "" ? "" : currentWebsite}
                    </a>
                  </td>
                  <td className="td social" colSpan={1} style={{
                    width: "33%",
                    textAlign: "end",
                    padding: "2px 0 0",
                    verticalAlign: "top",
                  }}>
                    {socials.map(({ src, href, alt }) => (
                      <a href={href} target="_blank" rel="noreferrer" style={{ paddingLeft: 8 }}>
                        <img src={src} alt={alt} width={16} height={16} />
                      </a>
                    )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table >
  )
};

export default Signature;

