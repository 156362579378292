import {
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import ReactDOMServer from "react-dom/server";

import Signature from "./Signature";
import { baseUrl } from './constants'
import "./App.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
      "& .label-root": {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: "left",
      color: theme.palette.text.secondary,
      maxWidth: "650px",
      minWidth: "330px",
      width: '100%',
      margin: '2px' 
    },
    paperSignature: {
      maxWidth: "516px",
      width: "100%", 
      border: "1px solid #D1D7EA",
      height: '100%',
    },
    centeredImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "1rem",
      width: "150px",
    },
    centeredText: {
      textAlign: "center",
    },
    container: {
      padding: 0,
      minHeight: "40px",
      height: "100%",
      marginTop: "5px",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    commonButton: {
      backgroundColor: "#EBEBEB",
      color: "#373737",
      height: "40px",
      padding: "0",
    },
  })
);

export interface State {
  fullName: string;
  credentials: string;
  phone: string;
  website: string;
}

const initialState: State = {
  fullName: "",
  credentials: "",
  phone: "",
  website: `${baseUrl}`,
};

function App() {
  const classes = useStyles();
  const [state, setState] = React.useState<State>(initialState);

  const hasRequiredFields: boolean =
    !!state.fullName && !!state.phone && !!state.credentials;

  React.useEffect(() => {
    setState(initialState);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const showSignature = () => {

    if (state.fullName) {
      return (
        <>
          <Signature
            {...state}
          />
          <Container className={`${classes.container} wrapper`}>
            <Button
              onClick={copyToClipboard}
              disabled={!hasRequiredFields}
              style={{ width: "102px" }}
              className={classes.commonButton}
            >
              Copy
            </Button>
            <Button
              style={{ width: "176px" }}
              onClick={downloadHtmlFile}
              disabled={!hasRequiredFields}
              className={classes.commonButton}
            >
              Download HTML
            </Button>
            <Button
              style={{ width: "102px" }}
              disabled={!hasRequiredFields}
              className={classes.commonButton}
            >
              Edit
            </Button>
            <Button
              style={{ width: "102px" }}
              disabled={!hasRequiredFields}
              onClick={clearState}
              className={classes.commonButton}
            >
              Clear
            </Button>
          </Container>
        </>
      );
    }
    return <div>Please, input your data</div>;
  };

  const copyToClipboard = () => {
    let copyText = document.querySelector(".signature") as HTMLTableElement;
    copyText.style.width = "516px"
    const range = document.createRange();
    if (copyText) {
      range.selectNode(copyText);
    }
    const windowSelection = window.getSelection();
    if (windowSelection) {
      windowSelection.removeAllRanges();
      windowSelection.addRange(range);
    }
    console.log(windowSelection)
    try {
      let successful = document.execCommand("copy");
      console.log(successful ? "Success" : "Fail");
      copyText.style.width = "100%"
      setState((prevState) => ({
        ...prevState,
        copied: true,
      }));
    } catch (err) {
      console.log("Fail");
    }
  };

  const downloadHtmlFile = () => {
    const htmlSignature = ReactDOMServer.renderToStaticMarkup(
      <Signature {...state} />
    );
    const lowerCaseName = state.fullName.toLowerCase();
    const nameSplit = lowerCaseName.split(" ");
    const firstInitial = nameSplit[0].charAt(0);
    const lastName = nameSplit[1];
    const blob = new Blob([htmlSignature]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = fileDownloadUrl;
    link.setAttribute("download", `${firstInitial}${lastName}.htm`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const isStateChanged = () => {
    return JSON.stringify(state) === JSON.stringify(initialState);
  };

  const clearState = () => {
    setState(initialState);
  };

  return (
    <Container>
      <img
        className={classes.centeredImage}
        src="/logo.png"
        alt="modsen-logo"
      />
      <Typography variant="h2" gutterBottom className={classes.centeredText}>
        Signature generator
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        className={classes.centeredText}
      ></Typography>
      <Grid container spacing={3} >
          <Paper className={classes.paper}>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                autoFocus
                fullWidth={true}
                required
                label="Full Name"
                value={state.fullName}
                name={"fullName"}
                onChange={handleChange}
              />
              <TextField
                fullWidth={true}
                label="Credentials"
                value={state.credentials}
                name={"credentials"}
                required
                onChange={handleChange}
              />
              <TextField
                fullWidth={true}
                required
                label="Telephone (separate by comma)"
                value={state.phone}
                name={"phone"}
                onChange={handleChange}
              />
              <TextField
                fullWidth={true}
                label="Website"
                value={state.website}
                name={"website"}
                onChange={handleChange}
              />
              <Button
                disabled={isStateChanged()}
                onClick={clearState}
                color={"secondary"}
              >
                Clear
              </Button>
            </form>
          </Paper>
          <Paper className={`${classes.paper} ${classes.paperSignature}`}>{showSignature()}</Paper>
      </Grid>
    </Container >
  );
}

export default App;
